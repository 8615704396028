@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  height: 100%;
}

textarea:focus {
  outline: none;
  box-shadow: none;
}
.react-scroll-to-bottom--css-uzqrz-79elbk {
  position: relative;
}

.react-scroll-to-bottom--css-ikyem-1n7m0yu {
  height: 100%;
  overflow-y: auto;
  width: 100%;
}

.bg-page{
  background-image: url('assets/images/bg/bg-pages.jpg') !important;
}